define("ember-highcharts/templates/components/high-charts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "s16uDXw6",
    "block": "[[[10,0],[14,0,\"chart-container\"],[12],[13],[1,\"\\n\"],[18,1,[[33,1]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"chart\"]]",
    "moduleName": "ember-highcharts/templates/components/high-charts.hbs",
    "isStrictMode": false
  });
});