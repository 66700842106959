define('ember-deferred-content/components/deferred-content/settled-content', ['exports', 'ember-deferred-content/templates/components/deferred-content/settled-content'], function (exports, _settledContent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { Component } = Ember;

  exports.default = Component.extend({
    layout: _settledContent.default,
    tagName: ''
  });
});