define("ember-datetimepicker/components/date-time-picker", ["exports", "@ember/component", "@ember/runloop", "moment"], function (_exports, _component, _runloop, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function formatDate(date) {
    return (0, _moment.default)(date).format('YYYY/MM/DD H:mm');
  }
  var _default = _exports.default = _component.default.extend({
    tagName: 'input',
    classNames: ['date-time-picker'],
    init() {
      this._super();
      if (!this.options) {
        this.set('options', {});
      }
      this.set('_changeHandlerProxy', (0, _runloop.bind)(this, this._changeHandler));
    },
    _changeHandler(event) {
      let newValue = event.target.value;
      let oldValue = this.datetime;
      let newDatetime, newDatetimeFormat, oldDatetimeFormat;
      if (newValue) {
        newDatetime = new Date(newValue);
        newDatetimeFormat = formatDate(newDatetime);
      }
      if (oldValue) {
        oldDatetimeFormat = formatDate(oldValue);
      }
      if (newDatetimeFormat === oldDatetimeFormat) {
        return;
      }
      this.action(newDatetime);
    },
    _updateValue(shouldForceUpdatePicker) {
      let value;
      let {
        datetime
      } = this;
      if (datetime) {
        value = formatDate(datetime);
      } else {
        value = '';
      }
      let el = this.$();
      el.val(value);

      // is only needed for inline, changing value above didn't change the picker
      if (shouldForceUpdatePicker) {
        el.datetimepicker({
          value
        });
      }
    },
    _initDatetimepicker() {
      let {
        options
      } = this;
      this.$().datetimepicker(options).on('change', this._changeHandlerProxy);
    },
    didInsertElement() {
      this._updateValue();
      (0, _runloop.scheduleOnce)('afterRender', this, this._initDatetimepicker);
    },
    didUpdateAttrs() {
      this._updateValue(true);
    },
    willDestroyElement() {
      this.$().off('change', this._changeHandlerProxy).datetimepicker('destroy');
    }
  }).reopenClass({
    positionalParams: ['datetime']
  });
});