define("ember-highcharts/components/high-charts", ["exports", "@ember/polyfills", "@ember/component", "@ember/application", "@ember/object", "@ember/runloop", "ember-highcharts/utils/option-loader", "ember-highcharts/utils/chart-data", "ember-highcharts/templates/components/high-charts", "deepmerge"], function (_exports, _polyfills, _component, _application, _object, _runloop, _optionLoader, _chartData, _highCharts, _deepmerge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Map ember-highcharts modes to Highcharts methods
   * https://api.highcharts.com/class-reference/Highcharts.html
   */
  const CHART_TYPES = {
    StockChart: 'stockChart',
    Map: 'mapChart',
    undefined: 'chart'
  };
  var _default = _exports.default = _component.default.extend({
    layout: _highCharts.default,
    classNames: ['highcharts-wrapper'],
    content: undefined,
    mode: undefined,
    chartOptions: undefined,
    chart: null,
    theme: undefined,
    callback: undefined,
    buildOptions: (0, _object.computed)('chartOptions', 'content.[]', function () {
      let theme = (0, _object.get)(this, 'theme');
      if (theme === undefined) {
        theme = {};
      }
      let passedChartOptions = (0, _object.get)(this, 'chartOptions');
      if (passedChartOptions === undefined) {
        passedChartOptions = {};
      }
      let chartOptions = (0, _deepmerge.default)(theme, passedChartOptions);
      let chartContent = (0, _object.get)(this, 'content');

      // if 'no-data-to-display' module has been imported, keep empty series and leave it to highcharts to show no data label.
      if (!(0, _object.get)(this, 'content.length') && !Highcharts.Chart.prototype.showNoData) {
        chartContent = [{
          id: 'noData',
          data: 0,
          color: '#aaaaaa'
        }];
      }
      let defaults = {
        series: chartContent
      };
      return (0, _polyfills.assign)(defaults, chartOptions);
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      let {
        content,
        chart,
        mode
      } = (0, _object.getProperties)(this, 'content', 'chart', 'mode');
      if (!content || !chart) {
        return;
      }
      let isStockChart = mode === 'StockChart';

      // create maps to make series data easier to work with
      let contentSeriesMap = (0, _chartData.getSeriesMap)(content);
      let chartSeriesMap = (0, _chartData.getSeriesMap)(chart.series);

      // remove and update current series
      let chartSeriesToRemove = [];
      chart.series.forEach(series => {
        if (isStockChart && series.name.match(/^Navigator/)) {
          return;
        }
        let contentSeries = contentSeriesMap[series.name];
        if (!contentSeries) {
          return chartSeriesToRemove.push(series);
        }
        let updatedKeys = (0, _chartData.getSeriesChanges)(contentSeries, series);

        // call series.update() when other series attributes like pointStart have changed
        if (updatedKeys.length) {
          series.update(contentSeries, false);
        } else {
          series.setData(contentSeries.data, false);
        }
      });
      chartSeriesToRemove.forEach(series => series.remove(false));

      // add new series
      content.forEach(contentSeries => {
        // eslint-disable-next-line no-prototype-builtins
        if (!chartSeriesMap.hasOwnProperty(contentSeries.name)) {
          chart.addSeries(contentSeries, false);
        }
      });

      // reset navigator data
      if (isStockChart && chart.xAxis.length) {
        chart.xAxis[0].setExtremes();
      }
      return chart.redraw();
    },
    drawAfterRender() {
      _runloop.run.scheduleOnce('afterRender', this, 'draw');
    },
    draw() {
      let element = this.element && this.element.querySelector('.chart-container');
      let modeAttr = (0, _object.get)(this, 'mode') || undefined;
      let mode = CHART_TYPES[modeAttr];
      let completeChartOptions = [(0, _object.get)(this, 'buildOptions'), (0, _object.get)(this, 'callback')];
      if (element) {
        let chart = Highcharts[mode](element, ...completeChartOptions);
        (0, _object.set)(this, 'chart', chart);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this.drawAfterRender();
      (0, _optionLoader.setDefaultHighChartOptions)((0, _application.getOwner)(this));
    },
    willDestroyElement() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'chart')) {
        (0, _object.get)(this, 'chart').destroy();
      }
    }
  });
});