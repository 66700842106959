define("ember-star-rating/templates/components/star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3xAwmOML",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"svg\"],[15,\"width\",[36,3]],[15,\"height\",[36,4]],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[15,\"viewBox\",[36,5]],[12],[1,\"\\n    \"],[10,\"defs\"],[12],[1,\"\\n      \"],[10,\"linearGradient\"],[15,1,[29,[[36,6],\"-star-\",[30,2]]]],[14,\"x1\",\"0%\"],[14,\"y1\",\"0%\"],[14,\"x2\",\"100%\"],[14,\"y2\",\"0%\"],[12],[1,\"\\n        \"],[10,\"stop\"],[14,0,\"star-rating-filled\"],[14,\"offset\",\"0%\"],[15,\"stop-color\",[36,7]],[12],[13],[1,\"\\n        \"],[10,\"stop\"],[14,0,\"star-rating-base\"],[14,\"offset\",\"0%\"],[15,\"stop-color\",[36,8]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"path\"],[15,\"fill\",[29,[\"url(#\",[36,6],\"-star-\",[30,2],\")\"]]],[15,\"d\",[36,9]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"star\",\"index\"],false,[\"each\",\"-track-array\",\"stars\",\"width\",\"height\",\"viewBox\",\"elementId\",\"fillColor\",\"baseColor\",\"svgPath\"]]",
    "moduleName": "ember-star-rating/templates/components/star-rating.hbs",
    "isStrictMode": false
  });
});