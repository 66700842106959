define("@ember/test-waiters/wait-for", ["exports", "@ember/test-waiters/wait-for-promise", "@ember/test-waiters/build-waiter"], function (_exports, _waitForPromise, _buildWaiter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitFor;
  /**
   * A convenient utility function to simplify waiting for async. Can be used
   * in both decorator and function form. When applied to an async function, it
   * will cause tests to wait until the returned promise has resolves. When
   * applied to a generator function, it will cause tests to wait until the
   * returned iterator has run to completion, which is useful for wrapping
   * ember-concurrency task functions.
   *
   *
   * @public
   * @param promise {Function} An async function or a generator function
   * @param label {string} An optional string to identify the promise
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default Component.extend({
   *   doAsyncStuff: waitFor(async function doAsyncStuff() {
   *     await somethingAsync();
   *   }
   * });
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default class Friendz extends Component {
   *   @waitFor
   *   async doAsyncStuff() {
   *     await somethingAsync();
   *   }
   * }
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default Component.extend({
   *   doTaskStuff: task(waitFor(function* doTaskStuff() {
   *     yield somethingAsync();
   *   }
   * });
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default class Friendz extends Component {
   *   @task
   *   @waitFor
   *   *doTaskStuff() {
   *     yield somethingAsync();
   *   }
   * }
   *
   */

  function waitFor(...args) {
    let isFunction = args.length < 3;
    if (isFunction) {
      let [fn, label] = args;
      return wrapFunction(fn, label);
    } else {
      let [,, descriptor, label] = args;
      if (!false /* DEBUG */) {
        return descriptor;
      }
      let fn = descriptor.value;
      descriptor.value = wrapFunction(fn, label);
      return descriptor;
    }
  }
  function wrapFunction(fn, label) {
    if (!false /* DEBUG */) {
      return fn;
    }
    return function (...args) {
      let result = fn.call(this, ...args);
      if (isThenable(result)) {
        return (0, _waitForPromise.default)(result, label);
      } else if (isGenerator(result)) {
        return waitForGenerator(result, label);
      } else {
        return result;
      }
    };
  }
  function isThenable(maybePromise) {
    let type = typeof maybePromise;
    return (maybePromise !== null && type === 'object' || type === 'function') && typeof maybePromise.then === 'function';
  }
  function isGenerator(maybeGenerator) {
    // Because we don't have Symbol.iterator in IE11
    return typeof maybeGenerator.next === 'function' && typeof maybeGenerator.return === 'function' && typeof maybeGenerator.throw === 'function';
  }
  const GENERATOR_WAITER = (0, _buildWaiter.default)('@ember/test-waiters:generator-waiter');
  function waitForGenerator(generator, label) {
    GENERATOR_WAITER.beginAsync(generator, label);
    let isWaiting = true;
    function stopWaiting() {
      if (isWaiting) {
        GENERATOR_WAITER.endAsync(generator);
        isWaiting = false;
      }
    }
    return {
      next(...args) {
        let hasErrored = true;
        try {
          let val = generator.next(...args);
          hasErrored = false;
          if (val.done) {
            stopWaiting();
          }
          return val;
        } finally {
          // If generator.next() throws, we need to stop waiting. But if we catch
          // and re-throw exceptions, it could move the location from which the
          // uncaught exception is thrown, interfering with the developer
          // debugging experience if they have break-on-exceptions enabled. So we
          // use a boolean flag and a finally block to emulate a catch block.
          if (hasErrored) {
            stopWaiting();
          }
        }
      },
      return(...args) {
        stopWaiting();
        return generator.return(...args);
      },
      throw(...args) {
        stopWaiting();
        return generator.throw(...args);
      }
    };
  }
});