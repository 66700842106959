define('ember-aupac-control/components/aupac-control', ['exports', 'ember-aupac-control/templates/components/aupac-control'], function (exports, _aupacControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _aupacControl.default,

    //Public API
    errors: null,
    mandatory: false,
    label: '',
    feedbackSuccessIcon: 'glyphicon glyphicon-ok',
    feedbackWarnIcon: 'glyphicon glyphicon-warning-sign',
    feedbackErrorIcon: 'glyphicon glyphicon-remove',
    leftIcon: null,
    rightIcon: null,
    canShowErrors: false,

    //Private
    tagName: 'div',
    classNames: ['form-group', 'has-feedback'],
    classNameBindings: ['hasSuccess', 'hasWarning', 'hasError'],
    hasIcon: Ember.computed.or('leftIcon', 'rightIcon'),

    init() {
      this._super(...arguments);

      const errors = this.get('errors');
      if (Ember.isNone(errors)) {
        this.set('errors', []);
      }
    },

    status: Ember.computed('errors.length', function () {
      if (this.get('errors.length') > 0) {
        return 'error';
      } else {
        return 'success';
      }
    }),

    /**
     * Error calculations
     */
    hasSuccess: Ember.computed('status', 'canShowErrors', function () {
      const success = this.get('errors') && this.get('status') === 'success' && this.get('canShowErrors');
      this.set('success', success);
      return success;
    }),
    hasWarning: Ember.computed('status', 'canShowErrors', function () {
      const warning = this.get('errors') && this.get('status') === 'warning' && this.get('canShowErrors');
      this.set('warning', warning);
      return warning;
    }),
    hasError: Ember.computed('status', 'canShowErrors', function () {
      const error = this.get('errors') && this.get('status') === 'error' && this.get('canShowErrors');
      this.set('error', error);
      return error;
    }),

    /**
     * Icon Feedback
     */
    feedbackIcon: Ember.computed('status', 'canShowErrors', 'errors.length', function () {
      if (!this.get('canShowFeedback')) {
        return;
      }

      switch (this.get('status')) {
        case 'success':
          return ''; //this.get('feedbackSuccessIcon');
        case 'warning':
        case 'warn':
          return this.get('feedbackWarnIcon');
        case 'error':
          return this.get('feedbackErrorIcon');
        default:
          return null;
      }
    }),

    /**
     * Help Feedback
     */
    helpText: Ember.computed('errors.firstObject', function () {
      return this.get('errors.firstObject');
    }),

    /*
     Listen to the focus out of the form group and display the errors
     */
    canShowFeedback: Ember.computed.and('canShowErrors'), //, 'errors.length'
    focusOut() {
      return this.set('canShowErrors', true);
    }
  });
});